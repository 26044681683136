import React from 'react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet-async';
import { StaticImage } from 'gatsby-plugin-image';

import { Article } from '../theme/typography';
import { Navigation } from '../shared/components/navigation';
import { Footer } from '../shared/components/footer';
import { Products } from '../shared/components/products';
import { ContactFormSection } from '../shared/components/contactFormSection';

interface IndexProps {
  site: {
    buildTime: string;
  };
}

const Wytaczarki: React.FC<PageProps<IndexProps>> = () => {
  return (
    <Article>
      <Navigation />
      <Helmet
        htmlAttributes={{
          lang: 'pl',
        }}
        title="Wytaczarka mobilna / przenośna | Wizman"
        meta={[
          {
            name: 'description',
            content:
              'Wytaczarka mobilna to niezastąpione rozwiązanie w zakresie obróbki otworów współosiowych. Firma Wizman oferuje osprzęt do naprawy mobilnych / przenośnych wytaczarek, specjalistyczne narzędzia pomiarowe',
          },
        ]}
      />
      <section className="bg-white body-font w-full">
        <div className="container px-5 pt-32 mx-auto flex flex-col">
          <div className="lg:w-5/6 mx-auto">
            <div className="rounded-lg h-80 overflow-hidden mb-8">
              <StaticImage
                className="object-cover object-center h-full w-full"
                src="../images/wytaczarka-przenosna.jpg"
                alt="Wytaczarka mobilna - przenośna"
              />
            </div>
            <h1 className="text-3xl font-extrabold md:text-4xl">Wytaczarki mobilne / wytaczarki przenośne</h1>
            <div className="flex flex-col sm:flex-row">
              <div className="w-full sm:py-8 mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                <p className="mb-8 leading-relaxed text-gray-500">
                  Zgodnie z nazwą wytaczarka przenośna - to maszyna mobilna, a jej stosunkowo małe rozmiary pozwalają na
                  obróbkę otworów w dowolnych warunkach i miejscu: na wysokości, na placach budowy, pod ziemią, wszędzie
                  gdzie dostępne jest zasilanie 220V. Jest to profesjonalne narzędzie do regeneracji otworów –
                  wytaczania i napawania otworów, pozwala na obróbkę otworów we wszelkiego rodzaju większych gabarytowo
                  konstrukcjach stalowych, wszędzie tam gdzie stosowanie wytaczarek stacjonalnych było by utrudnione.
                  Obróbka otworów za pomocą wytaczarki mobilnej może obejmować pracę na takich mazynach jak: ramiona i
                  łyżki koparek oraz ładowarek, wysięgniki, uchwyty, cięgna czy ramy nośne ciężkich maszyn budowlanych,
                  wodzideł a także konstrukcjach stalowych. Mobilność urządzenia w stosunku do stacjonarnej jego wersji
                  daje taką zaletę, że może być wykorzystywane bezpośrednio na miejscu pracy reperowanej maszyny, bez
                  potrzeby kosztownego transportu, co stanowi duże ułatwienie logistyczne i całościową oszczedność
                  kosztów naprawy.
                </p>
                <h2 className="leading-relaxed font-bold text-xl mb-4">Wytaczanie otworów</h2>
                <p className="leading-relaxed text-gray-500">
                  Powiększenie lub poprawa jakości istniejących już otworów, czyli wytaczanie otworów uzależnione jest
                  od indywidualych potrzeb danego zlecenia. Możemy wyróżnić trzy główne metody wytaczania otworów:{' '}
                  <br />
                  <strong>Rozwiercanie</strong> – ta metoda wytaczania otworów umożliwia uzyskanie jednocześne wysokiej
                  jakości wykańczanej powierzchni, wraz z wąską tolerancją średnic.
                  <br /> <strong>Wytaczanie zgrubne</strong> – to inaczej obróbka zgrubna, polegająca w głównej mierze
                  na usunięciu metalu, w celu powiększenia już istniejących otworów. <br />
                  <strong>Wytaczanie wykańczające</strong> – przeznaczone jest do wykańczania istniejących otworów, w
                  celu uzyskania wąskiej tolerancji otworu, jego położenia, a także wysokiej jakości wykończenia
                  powierzchni.
                  <br />W dzisiejszych czasach wytaczanie otworów w różnego typu konstrukcjach stalowych, zwłaszcza w
                  tych o większych gabarytach, gdzie używanie obrabiarek stacjonarnych jest utrudnione, nie stanowi już
                  problemu dzięki wytaczarką mobilnym.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Products />
      <ContactFormSection />
      <Footer />
    </Article>
  );
};

export default Wytaczarki;
